<script setup lang="ts">
import CheckCircleIcon from '@/components/icons/CheckCircleIcon.vue';
import AlertCircleIcon from '@/components/icons/AlertCircleIcon.vue';
import InfoCircleIcon from '@/components/icons/InfoCircleIcon.vue';

const notificationStore = useNotificationStore();
const { width } = useWindowResize();

const isShowDismiss = computed(() => {
	return notificationStore.message.isShowDismiss;
});

const link = computed(() => {
	return notificationStore.message.link;
});

const linkName = computed(() => {
	return notificationStore.message.linkName;
});

const isExternalLink = computed(() => {
	return typeof notificationStore.message.link === 'string';
});

const className = computed(() => {
	return `notification-${notificationStore.message.type}`;
});

// const linkName = computed(() => {
// 	if (notificationStore.message.type === NOTIFICATION_TYPE.SUCCESS) {
// 		return 'View changes';
// 	}
// 	return 'Learn more';
// });

const strokeWidth = computed(() => {
	switch (notificationStore.message.type) {
		case NOTIFICATION_TYPE.SUCCESS:
			return '4';
		default:
			return '2';
	}
});

const iconColor = computed(() => {
	switch (notificationStore.message.type) {
		case NOTIFICATION_TYPE.SUCCESS:
			return '#079455';
		case NOTIFICATION_TYPE.ERROR:
			return '#D92D20';
		case NOTIFICATION_TYPE.WARNING:
			return '#DC6803';
		case NOTIFICATION_TYPE.INFO:
			return '#444CE7';
		default:
			return '';
	}
});

const notificationIconComponent = computed(() => {
	switch (notificationStore.message.type) {
		case NOTIFICATION_TYPE.ERROR:
		case NOTIFICATION_TYPE.WARNING:
			return AlertCircleIcon;
		case NOTIFICATION_TYPE.INFO:
			return InfoCircleIcon;
		default:
			return CheckCircleIcon;
	}
});

const position = computed(() => {
	return width.value <= SCREEN_SIZE.MOBILE ? 'top center' : 'top right';
});
</script>

<template>
  <VSnackbar
    v-model="notificationStore.message.isShow"
    :timeout="5000"
    rounded="lg"
    :location="position"
  >
    <div
      :class="['notification', className]"
    >
      <component
        :is="notificationIconComponent"
        :color="iconColor"
        width="20"
        height="20"
        :stroke-width="strokeWidth"
      />

      <div class="notification-content">
        <h2
          v-if="notificationStore.message.header"
          class="text-sm text-semibold"
        >
          {{ notificationStore.message.header }}
        </h2>
        <p
          v-if="notificationStore.message.content"
          class="text-sm text-regular"
        >
          {{ notificationStore.message.content }}
        </p>
        <div class="notification-action">
          <BaseButton
            v-if="isShowDismiss"
            class="dismiss-btn"
            variant="link"
            size="sm"
            @click="notificationStore.hideNotification()"
          >
            Dismiss
          </BaseButton>
          <template v-if="isExternalLink">
            <a
              v-if="!!link"
              :href="link.toString()"
              target="_blank"
            >
              <BaseButton
                class="link-btn"
                variant="link"
                size="sm"
                @click="notificationStore.hideNotification()"
              >
                {{ linkName }}
              </BaseButton>
            </a>
          </template>
          <template v-else>
            <RouterLink
              v-if="!!link"
              :to="link"
            >
              <BaseButton
                class="link-btn"
                variant="link"
                size="sm"
                @click="notificationStore.hideNotification()"
              >
                {{ linkName }}
              </BaseButton>
            </RouterLink>
          </template>
        </div>
      </div>
      <BaseButton
        class="icon-close"
        variant="subtle"
        color="gray"
        size="sm"
        icon-only
        @click="notificationStore.hideNotification()"
      >
        <CloseIcon :color="iconColor" />
      </BaseButton>
    </div>
  </VSnackbar>
</template>

<style lang="scss" scoped>
.notification {
  display: flex;
  flex-direction: row;
  gap: spacings-get(3);
  flex-wrap: wrap;
  align-content: center;
  min-height: rem(68);
  padding: spacings-get(4);
  @include border-radius-default;
  border-left: 8px solid;
  box-sizing: border-box;
  background-color: colors-get(base, white);

  .notification-content {
    width: rem(246);

    p {
      max-height: rem(100);
      overflow: auto;
    }
  }

  .notification-action {
    display: flex;
    column-gap: spacings-get(3);
    margin-top: rem(10);

    button {
      padding: 0;
    }

    &:empty {
      margin-top: 0;
    }
  }

  .icon-close {
    height: fit-content;
    padding: 0;
  }

  &.notification-success {
    color: colors-get(success, 700);
    border-color: colors-get(success, 500);

    .dismiss-btn {
      color: colors-get(success, 600);
    }

    .link-btn {
      color: colors-get(success, 700);
    }
  }

  &.notification-error {
    color: colors-get(error, 700);
    border-color: colors-get(error, 500);

    .dismiss-btn {
      color: colors-get(error, 600);
    }

    .link-btn {
      color: colors-get(error, 700);
    }
  }

  &.notification-warning {
    color: colors-get(warning, 700);
    border-color: colors-get(warning, 500);

    .dismiss-btn {
      color: colors-get(warning, 600);
    }

    .link-btn {
      color: colors-get(warning, 700);
    }
  }

  &.notification-info {
    color: colors-get(primary, 700);
    border-color: colors-get(primary, 500);

    .dismiss-btn {
      color: colors-get(primary, 600);
    }

    .link-btn {
      color: colors-get(primary, 700);
    }
  }
}
</style>
